<template>
	<h1>Manage User Profiles</h1>
	<div v-if="dataError" class="critical-error">An error has occurred.</div>
	<div v-else-if="profiles === null" class="loading-indicator">
		<img src="@/assets/images/loading.gif">
	</div>
	<div v-else>
		<div v-if="canCreate" id="create-profile-wrapper" class="form">
			<button type="button" @click="createProfile">Create New Profile</button>
		</div>
		<div v-if="profiles.length == 0" class="no-profiles">
			There are no user profiles to display.
		</div>
		<table v-else id="profile-list" class="data-table">
			<tr>
				<th>Profile Name</th>
				<th v-if="canEdit || canDelete" :colspan="(canEdit && canDelete) ? 2 : 1">{{(canEdit && canDelete) ? 'Actions' : (canEdit ? 'Edit' : 'Delete')}}</th>
			</tr>
			<tr v-for="profile in profiles">
				<td>{{profile.profile_name}}</td>
				<td v-if="canEdit" class="edit-cell" @click="editProfile(profile)" title="Edit Profile">&#x1F589;</td>
				<td v-if="canDelete" class="delete-cell" @click="deleteProfile(profile)" title="Delete Profile">X</td>
			</tr>
		</table>
		<pagination v-model="displayPage" :data="profiles" @page-changed="loadProfiles"></pagination>
		<edit-user-profile-form v-if="editingProfile" :profile-id="editedProfile" @close="editingProfile = false" @completed="loadProfiles(false)"></edit-user-profile-form>
		<delete-user-profile-confirmation v-if="deletingProfile" :profile="deletingProfile" @close="deletingProfile = null" @completed="loadProfiles(false)"></delete-user-profile-confirmation>
	</div>
</template>

<script>
	import Pagination from '@/components/Pagination';
	import EditUserProfileForm from '@/components/Modals/UserProfiles/EditUserProfileForm';
	import DeleteUserProfileConfirmation from '@/components/Modals/UserProfiles/DeleteUserProfileConfirmation';
	import { mapGetters } from 'vuex';
	
	export default {
		data() {
			return {
				profiles: null,
				dataError: false,
				editingProfile: false,
				editedProfile: null,
				deletingProfile: null,
				displayPage: 1
			}
		},
		computed: {
			canCreate() { // Used to determine whether the authenticated user has the appropriate permission to create user profiles.
				return (this.hasPermission('user-profiles', 'create') && this.hasPermission('users', 'manage-internal'));
			},
			canEdit() { // Used to determine whether the authenticated user has the appropriate permission to edit user profiles.
				return (this.hasPermission('user-profiles', 'edit') && this.hasPermission('users', 'manage-internal'));
			},
			canDelete() { // Used to determine whether the authenticated user has the appropriate permission to delete user profiles.
				return this.hasPermission('user-profiles', 'delete');
			},
			...mapGetters(['hasPermission'])
		},
		components: {
			Pagination, EditUserProfileForm, DeleteUserProfileConfirmation
		},
		async created() { // When the page is loaded, get the list of user profiles.
			await this.loadProfiles();
		},
		methods: {
			async loadProfiles(refresh = true) { // Performs the API request to get the list of user profiles.
				// If we're visually refreshing the page (rather than stealthily updating the user profile list), set the user profile list to NULL to display the loading indicator.
				if(refresh) {
					this.profiles = null;
				}
				
				// Perform the API request to get the list of user profiles.
				try {
					const response = await this.HTTP.get(`user-profiles?page=${this.displayPage}`);
					this.profiles = response.data.data;
				} catch(error) { // If there was an error obtaining the user profile list, display the generic error message.
					this.dataError = true;
				}
			},
			createProfile() { // Displays the modal for creating a new user profile.
				this.editedProfile = null;
				this.editingProfile = true;
			},
			editProfile(profile) { // Displays the edit user profile modal for the user profile at the given index in the user profiles array.
				this.editedProfile = profile.id;
				this.editingProfile = true;
			},
			deleteProfile(profile) { // Displays the deletion confirmation modal for the user profile at the given index in the user profiles array.
				this.deletingProfile = profile;
			}
		}
	}
</script>

<style scoped lang="scss">
	#create-profile-wrapper {
		text-align:right;
		margin-bottom:20px;
	}
	
	#create-profile-wrapper, .pagination {
		margin-right:25%;
	}
	
	.no-profiles {
		text-align:center;
	}
</style>